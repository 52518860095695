<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'app',
  props: {
  },
  mounted() {
  },

  created() {
    this.$root.$session.layout = 'system';
  },
  
  
}
</script>